.category-img img {
    background-color: #E3F4FB;
    padding: 15px 15px;
    width: 30%;
    margin: 20px 0px;
}
.category-head h5 {
    font-size: 21px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 0;
}

.category-img {
    display: flex;
    align-items: center;
}

.category-text {
    padding-left: 20px;
}

.category-head h2 {
    font-size: 49px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 600;
}

.pagination-all nav ul li a {
    color: #00151F;
    font-family: 'Poppins';
}

.pagination-all {
    padding-top: 90px;
}


.category-text h5 {
    font-size: 14px;
    color: #00151f;
}

.allthings-table button {
    font-size: 12px;
    background-color: #249fdb;
    width: 70%;
    border: none;
    padding: 12px;
    color: #ffffff;
    font-family: 'Poppins';
    letter-spacing: 1px;
    margin: 0 auto;
    cursor: pointer;
    outline: none;
}

.allthings-table {
    text-align: center;
}