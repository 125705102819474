.state-ul ul {
    padding: 0px 21px;
}

.state-ul ul li a {
    font-size: 13px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 600;
}

.find-frnchise h3 {
    font-size: 25px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 500;
}

.find-frnchise p {
    font-size: 13px;
}

.all-things-newd {
    background-color: #E3F4FB;
    padding: 30px;
    border: 1px solid #249FD8;
}

.all-things-newd .form-group select {
    background-color: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: unset;
}

.find-frnchise p {
    font-size: 12px;
}

.all-things-newd select#exampleFormControlSelect1 {
    font-size: 12px;
    color: #7070709e;
    font-family: 'Poppins';
    height: 50px;
}

.by-state-head p {
    font-size: 15px;
    color: #249FDB;
    margin: 0;
    font-family: 'Poppins';
    font-weight: 600;
}

.by-state-head h2 {
    font-size: 49px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 600;
}

section.by-state-sec {
    /* display: flex; */
    padding: 70px;
}

section.by-state-sec .row {
    display: flex;
    /* align-items: center; */
}

::marker {
    color: #249FDB;
    border-radius: unset !important;
}

.state-ul ul li a {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 400;
    white-space: nowrap;
    line-height: 50px;
}

section.by-state-sec .col-md-8 {
    padding-right: 100px;
}

.color-black ul li a {
    line-height: 3;
}