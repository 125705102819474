.atax-dv h4 span {
    font-size: 22px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 400;
    float: right;
}

.atax-dv p {
    font-size: 16px;
    color: #858585;
    font-family: 'Poppins';
    font-weight: 400;
}

.atax-dv {
    border: 1px solid #DBDBDB;
    padding: 5px 20px;
    position: relative;
}

.atax-dv h4 {
    font-size: 24px;
    color: #242A2D;
    font-family: 'Poppins';
    font-weight: 600;
}

.atax-dv h3 {
    font-size: 24px;
    color: #242A2D;
    font-family: 'Poppins';
    font-weight: 600;
}

.atax-dv h3 span {
    padding-left: 20px;
}

.req-head h3 {
    font-size: 25px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 600;
}

.req-head p {
    font-size: 16px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 400;
}

.form-buctket-done-btn button {
    background-color: #249FDB;
    padding: 10px 20px;
    font-size: 18px;
    color: #ffffff;
    font-family: 'Poppins';
    border: none;
    width: 40%;
    margin: 0 auto;
}

.form-buctket-done-btn {
    text-align: center;
}

section.request-sec {
    padding: 90px;
}

.all-cover-things {
    background-color: #E3F4FB;
    padding: 40px 30px;
}

.all-cover-things textarea#exampleFormControlTextarea1::placeholder {
    color: #0000005e;
    /* resize: none; */
}


.all-cover-things textarea#exampleFormControlTextarea1 {
    height: 115px;
    resize: none;
    border-radius: no;
    border-radius: unset;
}

.atax-dv {
    border: 1px solid #DBDBDB;
    padding: 5px 20px;
    position: relative;
    margin-top: 12px;
    background-color: #ffffffff;
}

.all-cover-things input#exampleInputEmail1 {
    border-radius: uun;
    border-radius: unset;
}

.dv-close {
    position: absolute;
    top: 8px;
    right: 10px;
}

