.terms-text h3 {
    font-size: 16px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 600;
    border-bottom: 1px solid #D4D4D4;
}

.terms-text p {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 400;
    padding-top: 20px;
}

.terms-text h3 {
    font-size: 16px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 600;
    border-bottom: 1px solid #D4D4D4;
    margin: 0;
    padding-bottom: 20px;
}

section.terms-sec .container {
    padding: 60px;
    border: 1px solid #D4D4D4;
}

section.terms-sec {
    padding: 110px;
}

.terms-head h3 {
    padding-bottom: 30px;
}