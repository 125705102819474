.finance-buisness h5 {
    font-size: 21px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 600;
    margin: 0;
}

.finance-buisness h2 {
    font-size: 49px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 600;
    padding-bottom: 27px;
}
.finance-buisness p {
    font-size: 13px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 400;
}

.finance-buisness p span {
    font-weight: 600;
}

.ul-finance ul {
    list-style: auto;
    padding: 0px1 0px;
    padding: 0px 16px;
}

.ul-finance li {
    font-size: 12px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 400;
}

.ul-finance ul li span {
    font-weight: 600;
}

section.financing-sec {
    padding: 80px;
}

section.obligation-sec {
    background-color: #000000;
    text-align: center;
    padding: 50px;
}
.obligation-sec h4 {
    font-size: 21px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-weight: 600;
}

.obligation-sec h2 {
    font-size: 40px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-weight: 600;
}

.free-consultation a {
    font-size: 16px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-weight: 400;
    background-color: #249FDB;
    padding: 10px 40px;
}

.free-consultation {
    margin-top: 60px;
}

.finance-text h5 {
    font-size: 17px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 500;
}

.finance-text p {
    font-size: 14px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 400;
    margin: 0;
}

.pic-and-text-finance-text {
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
}

section.add-fax-sec {
    padding: 0px;
    padding-bottom: 50px;
}

.finance-text {
    padding-left: 14px;
}

.free-consultation button {
    font-size: 12px;
    color: #ffffff;
    font-family: "Poppins";
    font-weight: 400;
    background-color: #249fdb;
    padding: 10px 21px;
    line-height: 1;
    border: 1px solid #249fdb;
    width: 30%;
    border-radius: unset;
}

.fax-padding {
    padding-top: 40px !important;
}