.about-head h5 {
    font-size: 20px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 600;
    margin: 0;
    text-transform: capitalize;
}

.about-head h2 {
    font-size: 49px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 600;
}

.about-head p {
    font-size: 14px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 400;
    margin: 22px 0px;
}

section.about-sec {
    padding: 70px;
}

.about-two-pics img {
    width: 10%;
}

.about-two-pics img {
    margin-right: 30px;
}