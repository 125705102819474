.our-process h5 {
    font-size: 12px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 500;
}

.our-process p {
    font-size: 10px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 400;
}
.our-process {
    box-shadow: -8px -9px 12px #7070706e;
    padding: 60px 40px;
    overflow: hidden;
    z-index: 4;
    position: relative;
    height: 270px;
}
.our-process::before {
    content: "\f054";
    font-family: 'FontAwesome';
    font-size: 70px;
    position: absolute;
    color: #249FDB;
    left: -11px;
    z-index: -3;
}

section.our-process-sec .col-md-2 {
    padding: 0;
}

.our-process-head h5 {
    font-size: 21px;
    color: #249FDB;
    font-family: 'Poppins';
    margin: 0;
    font-weight: 600;
}

.our-process-head h2 {
    font-size: 40px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 600;
}

.our-process-head {
    text-align: center;
    padding-bottom: 40px;
}

section.our-process-sec {
    padding: 90px 0px;
}