section.franchise101_page .head-sub-head {
    padding: 3rem 0;
}
section.franchise101_page div#accordion button.btn.btn-link {
    color: #000;
    font-family: "Poppins";
    font-weight: bold;
    position: relative;
    width: 100%;
    text-align: left;
}
section.franchise101_page div#accordion .card-body {
    color: #00151F;
    font-size: 14px;
}
section.franchise101_page div#accordion .card-header {
    background: transparent;
    /* border-bottom: 0; */
    position: relative;
}
section.franchise101_page div#accordion button.btn.btn-link::after {
    content: "-";
    font-family: "Poppins";
    position: absolute;
    right: 10px;
    font-weight: 100;
    top: 5px;
    border: 1px solid #000;
    border-radius: 50%;
    width: 30px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
}
section.franchise101_page div#accordion button.btn.btn-link.collapsed::after {
    content: "+";
    font-family: "Poppins";
    position: absolute;
    right: 10px;
    font-weight: 100;
    top: 5px;
    border: 1px solid #000;
    border-radius: 50%;
    width: 30px;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    justify-content: center;
}
section.franchise101_page {
    padding-bottom: 3rem;
}