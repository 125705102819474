.all-forms-sec h4 {
    font-size: 25px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 600;
}

.all-forms-sec h3 {
    font-size: 25px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 600;
}

.all-forms-sec p {
    font-size: 12px;
    color: #00151fa8;
    font-family: 'Poppins';
    font-weight: 400;
}

.all-forms-sec {
    background-color: #E3F4FB;
    border: 1px solid #249FD8;
    padding: 40px 30px;
}
.all-forms-sec  form label {
    color: #00151F;
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 400;
    padding-left: 13px;
    margin: 20px 0px;
}

.form-submit-btn button {
    background-color: #249FDB;
    width: 100%;
    border: none;
    padding: 10px;
    color: #fff;
    text-decoration: unset;
}

.all-forms-sec .form-group input {
    height: 55px;
    border-radius: unset;
}

.cash-pro-img {
    position: relative;
}

.play-btn {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: unset;
    display: flex;
    align-items: center;
    justify-content: center;
}

.play-btn i {
    color: #249FDB;
    background-image: url('../img/playback.png');
    background-repeat: no-repeat;
    width: auto;
    background-size: 100% 100%;
    padding: 26px 29px;
}

/* .dollar-img img {
    background-color: #141414;
    padding: 10px;
    border-radius: 40px;
} */

.process-box {
    display: flex;
}

.process-para p {
    font-size: 10px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 600;
    padding-left: 12px;
}

.process-head h3 {
    font-size: 42px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 600;
}

.process-head {
    padding: 20px 0px;
}

section.cash-program-sec {
    padding: 70px 0px;
}

.franchise-velocity {
    padding-top: 80px;
    padding-bottom: 40px;
}

.all-forms-sec form label span {
    color: #249FDB;
}

select#state {
    border-radius: unset;
    color: #0000007d;
    padding: 4px;
}

section.category-sec {
    padding: 100px 0px;
}

.dollar-img .img-fluid {
    max-width: 100px;
    height: auto;
}