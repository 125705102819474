@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Header Css Start Here */
#headerTop {
	/* background: var(--first-color); */
	padding: 0;
	background-color: white;
	margin-top: 44px;
	padding: 6px;
}

.logoDv {
	padding-top: 10px;
}
#headerTop .navbar_menus ul {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
}
#headerTop .navbar_menus ul li {
	display: inline-block;
	margin: 0 7px;
}
#headerTop .navbar_menus ul.menus li.menu-items {
	position: relative;
}
#headerTop .navbar_menus ul.menus li.menu-items:before {
	content: "";
	background: var(--second-color);
	width: 0%;
	height: 2px;
	position: absolute;
	left: 0;
	bottom: 0;
	transition: 0.6s;
}
#headerTop .navbar_menus ul.menus li.menu-items:hover:before {
	width: 100%;
}
#headerTop .navbar_menus ul li .dropdown button.btn {
	padding: 0;
	background: transparent;
	border: 0;
	font-family: "sf-text-pro";
	outline: none;
	box-shadow: none;
	font-size: 12px;
	color: #333333;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	padding-right: 5px;
}
#headerTop .navbar_menus ul li .dropdown button.btn:after {
	display: none;
}
#headerTop .navbar_menus ul li .dropdown ul.dropdown-menu li:last-child {
	border: 0;
}

section.find-franchise-sec .form-control {
	font-family: "Poppins";
	font-size: 13px;
	font-weight: 600;
}
#headerTop .navbar_menus ul li a {
	text-decoration: none;
	font-size: 12px;
	color: #333333c7;
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	padding-right: 6px;
	padding: 10px 17px;
}
#headerTop .navbar_menus ul li .dropdown ul.dropdown-menu li a {

  color: #000;
  /* font-family: "sf pro text"; */
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 1px;

	color: #000;
	/* font-family: "sf pro text"; */
	font-family: "Poppins";
	font-weight: 400;
	font-size: 11px;
	line-height: 1px;

}
#headerTop .navbar_menus ul li .dropdown ul.dropdown-menu li {
	display: block;
	padding: 4px 0;
	/* border-bottom: 1px solid var(--second-color); */
	text-align: left;
	position: relative;
	border-bottom: 1px solid #70707012;
	padding: 5px;
	white-space: nowrap;
}
#headerTop .navbar_menus ul li .dropdown ul.dropdown-menu {
	border-radius: 0;
	border: 0;
	margin-top: 0px !important;
	border-top: 2px solid #249fdb;
}
#headerTop .right-menus {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
#headerTop .right-menus ul.icons {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
}
#headerTop .right-menus .social_medias {
	display: flex;
	align-items: center;
}
#headerTop .right-menus ul.icons li {
	margin: 0 5px 0 0px;
}
#headerTop .right-menus ul.icons li a {
	color: var(--second-color);
}
#headerTop .right-menus .social_medias label {
	color: #fff;
	font-size: 14px;
	margin-right: 15px;
}
#headerTop .right-menus ul.icons li a i {
	border: 2px solid var(--second-color);
	width: 30px;
	height: 30px;
	line-height: 26px;
	text-align: center;
	border-radius: 50%;
	font-size: 14px;
	transition: 0.6s;
}
.navbar_menus .dropdown:hover .dropdown-menu {
	display: block !important;
}
#headerTop .right-menus ul.icons li a:hover i {
	background: var(--second-color);
	color: #000;
	border-color: #000;
	box-shadow: 0px 0px 0px 2px var(--second-color);
}
#headerTop .right-menus .search-icons button {
	border: 0;
	background: transparent;
	color: var(--second-color);
	padding: 0;
}
#headerTop
	.navbar_menus
	ul
	li
	.dropdown
	ul.dropdown-menu
	li.inner-dropdown
	ul.inner-dropdown-menu {
	position: absolute;
	right: -103%;
	background: var(--second-color);
	width: 270px;
	opacity: 0;
	transition: 0.6s;
	top: 0;
}
#headerTop
	.navbar_menus
	ul
	li
	.dropdown
	ul.dropdown-menu
	li.inner-dropdown:hover
	ul.inner-dropdown-menu {
	opacity: 1;
}
#headerTop
	.navbar_menus
	ul
	li
	.dropdown
	ul.dropdown-menu
	li.inner-dropdown
	ul.inner-dropdown-menu
	li
	a {
	padding: 0 15px;
}
#headerTop
	.navbar_menus
	ul
	li
	.dropdown
	ul.dropdown-menu
	li.inner-dropdown
	ul.inner-dropdown-menu
	li.dropdown-item {
	margin: 0;
}
@media (max-width: 1399px) {
	#headerTop .navbar_menus ul li a {
		font-size: 11px;
	}
	#headerTop
		.navbar_menus
		ul
		li
		.dropdown
		ul.dropdown-menu
		li.inner-dropdown
		ul.inner-dropdown-menu {
		right: -123%;
	}
	.Monetization h3 {
		font-size: 28px;
	}

	h3.gettingit {
		font-size: 16px !important;
	}
}
@media (max-width: 1350px) {
	#headerTop .navbar_menus ul li {
		margin: 0 4px;
	}
}
@media (max-width: 1100px) {
	#headerTop .navbar_menus ul li a {
		font-size: 11px;
	}
	#headerTop .navbar_menus ul li .dropdown button.btn {
		font-size: 11px;
	}
	#headerTop .right-menus .social_medias label {
		display: none;
	}
}
@media (max-width: 991px) {
	#headerTop .navbar_menus {
		display: none;
	}
	#headerTop .right-menus {
		justify-content: end;
	}
	#headerTop .right-menus .search-icons {
		margin: 0 15px;
	}
	#headerTop .right-menus .search-icons i {
		font-size: 20px;
	}
}

header {
	position: absolute;
	width: 100%;
	z-index: 2;
}

section.nav-top {
	position: absolute;
	width: 100%;
	z-index: 2;
	padding-top: 10px;
}

.two-links span {
	padding-right: 18px;
}

li.nav-btn a {
	font-size: 12px;
	color: #ffffff !important;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	background-color: #249fdb;
	padding: 10px;
}

.navbar_menus {
	float: right;
}

.two-links span a {
	font-size: 14px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 500;
}

.login a {
	font-size: 14px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 400;
	padding-right: 10px;
}

.login {

  display: flex;
  justify-content: right;
  cursor: pointer;

	display: flex;
	justify-content: right;

}

.seacrh i {
	color: #ffffff;
	border-left: 2px solid #ffffff;
	padding-left: 10px;
	position: relative;
	left: 8px;
}

/* Header Css End Here */

/* Begin of   Banner section  */

section.banner {
	background-image: url("../img/banner.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 100%;
	padding-top: 40px;
	padding-bottom: 14px;
	height: 360px;
	position: relative;
}

.banner-text h2 {
	font-size: 43px;
	color: #ffffff;
	font-family: "Poppins", sans-serif;
	font-weight: 700;
}

.banner-text p {
	font-size: 18px;
	color: #ffffff;
	font-family: "Poppins", sans-serif;
}

.banner-text p span a {
	color: #ffc300;
	font-weight: 700;
}

.explore h3 {
	font-size: 13.24px;
	color: #ffffff;
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	margin: 0;
}

.navbar_menus ul li {
	padding: 10px;
}
.explore i {
	color: #ffffff;
}

.explore {
	text-align: center;
	position: absolute;
	bottom: 8px;
	/* display: flex; */
	left: 0;
	right: 0;
}
.banner-text {
	/* padding-bottom: 42px; */
	position: relative;
	top: 140px;
}
/* End of Banner section  */

/* Inner banner section  */
.Inner-banner-text h5 {
	font-size: 16px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 300;
	text-align: center;
}

.Inner-banner-text {
	position: relative;
	top: 240px;
}

.Inner-banner-text h5 span {
	font-weight: 600;
}
/* End of Inner banner section  */

/* Begin of Featured section  */
.main-head h2 {
	font-size: 33px;
	color: #00151f;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}

.main-head {
	text-align: center;
	padding: 40px 0px;
}

section.find-franchise-sec {
	padding-bottom: 40px;
}
.search-btn a {
	background-color: #249fdb;
	padding: 8px 40px;
}

.search-btn a {
	font-size: 15px;
	color: #ffffff;
	font-family: "Poppins", sans-serif;
	font-weight: 300;
}
select#state {
	border-radius: unset;
}
section.find-franchise-sec .row {
	display: flex;
	align-items: center;
}
/* End of Featured section  */

/* Begin of Blue section  */
section.blue-sec {
	background-color: #249fdb;
	text-align: center;
	padding: 15px;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 2;
}

section.blue-sec h4 {
	font-size: 14px;
	color: #ffffff;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	margin: 0;
}

/* End of Blue section  */

/* Begin of Featured section  */
.featured-input {
	background-color: #d4d5d5;
	text-align: center;
	padding: 10px;
	cursor: pointer;
}
.featured-sec-head h2 {
	font-size: 33px;
	color: #00151f;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}

.featured-sec-head {
	text-align: center;
}

.featured-text h5 {
	font-size: 18px;
	color: #242a2d;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}

.featured-text p {
	font-size: 12px;
	color: #858585;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.featured-text p a {
	color: #249fdb;
	font-weight: 400;
	padding-left: 8px;
	text-decoration: underline;
}

.featured-bold p {
	font-size: 14px;
	color: #242a2d;
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	margin: 0;
	position: relative;
}
.featured-bold p span {
	font-weight: 500;
	padding-left: 20px;
}

.featured-box {
	position: relative;
}

.featured-input label {
	font-size: 15px;
	color: #ffffff;
	font-family: "Poppins", sans-serif;
	/* padding-left: 10px; */
	margin: 0;
}

.f-img {
	position: relative;
}

.inner-img {
	position: absolute;
	top: 108px;
	bottom: unset;
	left: 25px;
}

.all-text-things {
	padding: 28px 14px 22px;
	z-index: 999999999;
	position: relative;
}

.inner-img img {
	width: 60%;
}

/* .featured-bold::before {
  content: '';
  position: absolute;
  width: 83%;
  height: 1px;
  background-color: #D4D5D5;
  bottom: 60px;
} */
.all-text-things .featured-bold {
	border-bottom: 1px solid #ddd;
	padding-bottom: 20px;
}
section.featured-sec {
	background-color: #f3f3f3;
	padding: 50px;
}

.featured-sec-head h2 {
	font-size: 41px;
	color: #00151f;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	padding-bottom: 30px;
}

.featured-box {
	position: relative;
	border: 1px solid #dbdbdb;
	background-color: #ffffff;
}
/* End of Featured section  */

/* Begin Cash section  */
.head-sub-head h5 {
	font-size: 18px;
	color: #249fdb;
	font-family: "Poppins";
	font-weight: 600;
	margin: 0 0 8px 0;
}

.head-sub-head h3 {
	font-size: 35px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
	letter-spacing: 1px;
	padding-bottom: 15px;
}

.cash-Head-para p {
	font-size: 13px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 500;
}

.sign-up-btn a {
	font-size: 12px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 400;
	background-color: #249fdb;
	padding: 14px 40px;
}
section.cash-sec {
	padding: 140px;
}

section.cash-sec .row {
	display: flex;
	align-items: center;
}

.register-now-btn {
	line-height: 80px;
}

.sign-up-btn {
	line-height: 63px;
}

.sign-up-btn button {
	padding: 20px;
	border-radius: unset;
}

section.what-we-do-sec {
	display: flex;
	align-items: center;
}
/* End of Cash section  */

/* Begin of contact section  */
.head-sub-head p {
	font-size: 12px;
	color: #141414;
	font-family: "Poppins";
	font-weight: 400;
}

.all-form input {
	border: 1px solid #e2e2e2;
	border-radius: unset;
	width: 100;
	width: 100%;
	padding: 20px 10px;
}
.all-form textarea {
	border: 1px solid #e2e2e2;
	border-radius: unset;
	width: 100%;
	resize: none;
	height: 160px !important;
}

.all-form {
	width: 100%;
	margin: 0 auto;
}

.all-forms {
	width: 70% !important;
	margin: 0 auto;
}
.form-btn button {
	background-color: #249fdb;
	padding: 10px 40px;
	border: none;
	font-size: 14px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 400;
	margin-top: 24px;
}
section.contact-sec .row {
	display: flex;
	align-items: center;
}

section.contact-sec {

  padding-top: 60px;
  padding-bottom: 30px;
  background: #fbfbfb;

	padding-top: 60px;
	padding-bottom: 60px;
	background: #fbfbfb;

}

.submit-dv button {
	font-size: 16px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 400;
	background-color: #249fdb;
	width: 100%;
	border: none;
	padding: 10px;
}
.all-form form .form-group .form-control {
	background: #fff;
	font-family: "Poppins";
	font-weight: 400;
}
.all-form form .form-group .form-control::placeholder {
	color: #7b7b7b;
	font-weight: 500;
}
.contact-sec .head-sub-head h3 {
	font-size: 26px;
	line-height: 30px;
}
.contact-head h3 {
	font-size: 30px;
	color: #00151f;
	font-family: "Poppins";
	font-weight: 600;
	margin: 0;
}

.contact-head {
	text-align: center;
	padding-bottom: 40px;
}

.prefer-method h5 {
	font-size: 18px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 400;
}

.all-form .form-group input::placeholder {
	font-size: 14px;
	color: #00000069;
	font-family: "Poppins";
	font-weight: 400;
}

.all-form textarea::placeholder {
	font-size: 14px;
	color: #00000070;
	font-family: "Poppins";
	font-weight: 400;
}

.checkbox-custom {
	opacity: 0;
	position: absolute;
}

.all-form .checkbox-custom,
.checkbox-custom-label {
	display: inline-block;
	vertical-align: middle;
	margin: 5px;
	cursor: pointer;
}

.all-form .checkbox-custom + .checkbox-custom-label:before {
	content: "";
	background: #fff;
	border-radius: unset;
	border: 2px solid #ddd;
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 10px;
	padding: 10px;
	margin-right: 10px;
	text-align: center;
}

.all-form .checkbox-custom:checked + .checkbox-custom-label:before {
	content: "";
	display: inline-block;
	width: 1px;
	height: 5px;
	border: solid #249fdb;
	border-width: 0 3px 3px 0;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	border-radius: 0px;
	margin: 0px 15px 5px 5px;
}

.contact-img img {
	width: 100%;
	height: 600px;
	object-fit: cover;
}

/* End of contact section  */

/* Begin of Footer section  */
footer {
	background-image: url("../../../public/Images/footerback.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	width: 100%;
	padding-top: 80px;
}

.footer-head h3 {
	font-size: 16px;
	color: #ffffffab;
	font-family: "Poppins";
	font-weight: 500;
}

.footer-ul ul {
	padding: 0;
	padding-left: 18px;
}

.footer-ul ul li a {

  font-size: 12px;
  color: #ffffffa1;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: unset;

	font-size: 12px;
	color: #ffffffa1;
	font-family: "Poppins";
	font-weight: 500;

}

.footer-ul li::marker {
	color: #ffffff73;
}
.copy-right p {
	font-size: 15px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 400;
	margin: 0;
	padding: 16px 0px;
}

.footer-ul {
	padding-bottom: 30px;
}

.copy-right {
	text-align: center;
	/* border: 1px solid; */
	border-top: 1px solid #70707082;
}
.copy-right p span {
	color: #00a0fc;
}

.footer-logo {
	padding-bottom: 50px;
	width: 40%;
}
/* End of Footer section  */
/* live chat css starts here */
.Live-chat {
	position: fixed;
	bottom: 0;
	right: 0;
	background: transparent;
	z-index: 1;
	padding: 0.5rem 1rem;
	display: flex;
	transition: 0.6s;
	transform: translateX(65%);
}
.Live-chat.active {
	bottom: 80px;
}
.Live-chat:hover {
	transform: unset;
}
.Live-chat img {
	width: auto;
}
.Live-chat p {
	margin-bottom: 0;
	margin-left: 10px;
	color: white;
}
/* live chat css ends here */

.featured-sec-head.text-left p {
	font-size: 18px;
	color: #249fdb;
	font-family: "Poppins";
	font-weight: 600;
	margin: 0;
}

.pagination-in-avaliable {
	padding-top: 60px;
}

.pagination-in-avaliable ul li a {
	color: #000;
	margin: 0px 1px;
}

.conatct-sec-for-padding {
	padding-top: 80px !important;
}

/* Begin of Bucket section  */
.bucket-btn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.frnachise-basket button {
	font-size: 12px;
	background-color: #000;
	padding: 14px 20px;
	color: #ffffff;
}
.bucket-btn h4 {
	font-size: 16px;
	color: #ffffff;
}

.frnachise-basket {
	padding-left: 30px;
}
.frnachise-basket button {
	font-size: 13px;
	background-color: #000;
	padding: 3px 42px;
	color: #ffffff;
	font-family: "Poppins";
	text-align: end;
	margin: 0;
	cursor: pointer;
}

.frnachise-basket button img {
	position: relative;
	top: 8px;
	right: 16px;
}

.bucket-btn h4 span {
	background-color: #ffffff;
	color: #249fdb;
	padding: 6px 10px;
}

.atax-dv {
	border: 1px solid #dbdbdb;
	padding: 5px 20px;
}
/* End of Bucket section / */

.contact-sec textarea#exampleFormControlTextarea1 {
    padding: 25px 12px;
}

.for-margin-no {
	margin: 0;
}

.for-margin-no label {
	font-size: 16px;
	font-family: "Poppins";
	font-weight: 400;
}

.login a:hover {
	color: #249fdb;
	text-decoration: unset;
}

.login input {
	padding: 9px 15px !important;
	/* border-radius: unset; */
	border-radius: 60px;
	height: 30px;
}
h5.preferred {
    font-size: 15px;
}
.only-for-flex {
  display: flex;
  padding: 10px 0px;
}

.custom_check {

  /* display: block; */
  /* margin-bottom: 15px !important; */
  /* margin: 0px 10px; */
  margin-right: 10px !important;
}

a.page-link.active {
  background-color: #249fdb;
  color: #ffffff;
}

.form_start input {
  padding: 0px 17px !important;
}

.contact-head h3 {
    font-size: 30px;
    color: #00151f;
    font-family: "Poppins";
    font-weight: 600;
    margin: 0;
}

.logo-img img {
  position: relative;
}

.img-close {
  position: absolute;
  top: 35px;
  right: 25px;
}

.view-info-detail textarea#exampleFormControlTextarea1::placeholder {
  color: #000000;
}
.view-info-detail textarea#exampleFormControlTextarea1 {
  border: unset;
  resize: none;
  height: 100px;
  padding: 0;
}

	/* display: block; */
	/* margin-bottom: 15px !important; */
	/* margin: 0px 10px; */
	/* margin-right: 10px !important;
} */

.firstImages-feture img {
	width: 40%;
	margin-top: 5px;
}

.secondImages-feture img {
	width: 25%;
	margin-top: -5px;
}

.thirdImages-feture img {
	width: 25%;
	margin-top: -7px;
}

.fourImages-feture img {
	width: 30%;
	margin-top: 10px;
	background-color: #fff;
	padding: 10px;
}


.some-thng {
    font-size: 18px;
    color: #ffff;
    position: relative;
    top: 5px;
    left: 13px;
}

@media (max-width: 1440px) {
	.Live-chat {
		
		right: 18px;
		
	}
	
	}

@media (max-width: 1366px) {
.Live-chat {
    
    right: 18px;
    
}

}