section.blue-sec {
    background-color: #249fdb;
    text-align: center;
    padding: 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
}
.form-wrapper {
    background: #E3F4FB;
    padding: 40px 110px;
    border: 2px solid #89CCEA;
    margin-bottom: 20%;
    margin-top: 10%;
}
.checkbox-custom {
    opacity: 0;
    position: absolute;   
  }
  
  .checkbox-custom, .checkbox-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
  }
  
 .checkbox-custom + .checkbox-custom-label:before {
    content: '';
    background: #fff;
    border-radius: unset;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    padding: 10px;
    margin-right: 10px;
    text-align: center;
  }
  
    .checkbox-custom:checked + .checkbox-custom-label:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 5px;
    border: solid #249FDB;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    border-radius: 0px;
    margin: 0px 15px 5px 5px;
  }

  /* .form-group input#exampleInputEmail1 {
    padding: 22px;
  } */
.form-group input::placeholder {
    font-size: 14px;
    color: #00000069;
    font-family: 'Poppins';
    font-weight: 400;
  }
  .texterea textarea {
    width: 100%;
    height: 149px ! important;
    display: block;
    padding: 20px 24px;
    resize: none;

}
.texterea {
    margin-bottom: 30px;
}
 .texterea textarea::placeholder {
    font-size: 14px;
    color: #00000070;
    font-family: 'Poppins';
    font-weight: 400;
  }
  .heading-wrapper h4 {
    font-size: 25px;
    font-family: "poppins";
    font-weight: 600;
    color: #00151F;
}
.heading-wrapper {
    margin-top: 10px;
}
.heading-wrapper p {
    font-size: 16px;
    font-family: "poppins";
    font-weight: 400;
    color: #00151F;
    margin-bottom: 30px;
}
.heading-content {
    margin-top: 20px;
    margin-bottom: 17px;
}
.heading-content h5 {
    font-size: 18px;
    font-family: "poppins";
    font-weight: 500;
    color: #00151F;
}
.input-group label img {
    margin-right: 10px;
}
.input-group label {
    width: 100%;
    border: 3px dotted #D4D4D4;
    background: #FFFFFF;
    height: 46px;
    margin-top: 0px;
    padding-top: 8px;
}
span.addfile {
    font-size: 14px;
    font-family: "poppins";
    font-weight: 500;
    color: #249FDB;
}
.ordrop{
    font-size: 14px;
    font-family: "poppins";
    font-weight: 400;
    color: #B2B2B2;
}