.forgot-password {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 10px;
}
.login-btn button {
    font-size: 13px;
    color: Poppins;
    color: #ffffff;
    font-weight: 400;
    width: 100%;
    padding: 15px;
    background-color: #249FDB;
    border: unset;
    outline: none;
    cursor: pointer;
}

.login-text p {
    font-size: 12px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 400;
    padding-bottom: 20px;
}
.login-text p span {
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 600;
}

.login-text h2 {
    font-size: 50px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 700;
    padding-bottom: 30px;
}

.form-password a {
    font-size: 15px;
    color: #0E3A20;
    font-family: 'Poppins';
    font-weight: 500;
}

.login-things h5 {
    font-size: 15px;
    color: #707070;
    font-family: 'Poppins';
    font-weight: 300;
}

.login-things {
    text-align: center;
    padding-top: 30px;
}
.login-text a {
    color: #249FDB;
    text-decoration: unset;
}

section.login-sec .col-md-6 {
    padding: 0;
}

.login-text {
    padding: 110px 0px;
    padding-bottom: 0;
    width: 60%;
    margin: 0 auto;
}

.login-img img {
    position: relative;
}

.login-logo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-img img {
    width: 100%;
}

.franchise-consultant {
    margin: 10px 0px;
}