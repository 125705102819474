.dash-tab ul#pills-tab {
    display: block;
}

.my-franchise {
    background-color: #E3F4FB;
    padding: 20px;
}

.all-forms-accounts input {
    border: unset;
}

.account-head h3 {
    font-size: 22px;
    color: Account;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 700;
}

.account-btn a {
    background-color: #249FDB;
    padding: 10px 30px;
}


.account-btn a {
    color: #FFFFFF;
}

section.dashboard-sec {
    padding: 110px;
}

.card-btn a {
    font-size: 16px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 7px;
    /* background-color: #249FDB; */
    text-align: center;
    padding: 10px;
}

.card-info-all {
    width: 70%;
}

.card-btn {
    background-color: #249FDB;
    padding: 10px;
    text-align: center;
}

.dash-tab ul {
    background-color: #E3F4FB;
    padding: 50px 10px;
    border: 1px solid #249fd854;
    height: 510px;
    padding-top: 20px;
}

.form-group.change-password a {
    font-size: 12px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 600;
}

.dashboard-head h3 {
    font-size: 32px;
    color: #2A263D;
    font-family: 'Poppins';
    font-weight: 700;
    padding-bottom: 12px;
    padding-left: 20px;
}

.dashboard-head {
    border-bottom: 1px solid #7070704a;
}

.dash-tab ul li a {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 38px;
    padding-left: 24px;
    text-decoration: unset;
}

a.color-view {
    position: relative;
    left: 16px;
    /* color: #0000004d; */
    color: #00000073 !important;
}

.my-franchise-second-col h5 {
    font-size: 15px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 30px;
}

.my-franchise-third-col p {
    font-size: 12px;
    color: #1F2C35;
    font-family: 'Poppins';
    font-weight: 400;
}
.two-btns button {
    font-size: 14px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 600;
    text-transform: uppercase;
    padding-right: 20px;
    background-color: transparent;
    border: none;
}

.two-btns a {
    font-size: 14px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 600;
    text-transform: uppercase;
    padding-right: 20px;
    background-color: transparent;
    border: none;
}

.two-btns {
    float: right;
}

.my-franchise-head h3 {
    font-size: 22px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 700;
    padding-bottom: 20px;
} 

.all-table tr.for-background {
    background-color: className="for-backgrou";
    background-color: #E3F4FB;
}

.all-table td {
    font-size: 13px;
    color: #1F2C35;
    font-family: 'Poppins';
    font-weight: 500;
    padding: 4px 13px;
    padding: 30px 22px;
    text-align: center;
    white-space: nowrap;
}

.all-table th {
    font-size: 13px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 600;
    font-weight: pa;
    padding: 20px;
    text-align: center;
    white-space: nowrap;
}
.leads2-profile {
    border-collapse: initial;
    border-spacing: 0px 17px;
}

td.view-details {
    font-size: 14px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
}

td.view-details button {
    font-size: 14px;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    background-color: transparent;
    border: none;
}

.all-table h3 {
    font-size: 22px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 600;
}

section.leads-sec {
    padding: 90px 0px;
}

section.leads-sec .row {
    display: flex;
   
}

.purchase-input input {
    width: 10%;
    height: 75px;
    border: 1px solid #D4D4D4;
}

.purchase-input input::placeholder {
    font-size: 40px;
    color: #000000;
    font-family: 'Poppins';
    text-align: center;
    font-weight: 700;
}

.purchase-btn button {
    background-color: #249FDB;
    /* padding: 14px 70px; */
    width: 40%;
    padding: 16px;
    border: unset;
    font-size: 16px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-weight: 400;
}

section.purchase-sec .row {
    display: flex;
    align-items: baseline;
}

.purchase-input {
    padding: 30px 0px;
}

section.purchase-sec {
    padding: 100px;
}

section.dashboard-sec .row {
    display: flex;
}

.submit-btn-purhcase button {
    background-color: Cvv;
    background-color: #249FDB;
    width: 100%;
    padding: 14px 112px;
    border: unset;
    font-size: 18px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-weight: 500;
    margin-top: 30px;
}

.submit-btn-purhcase {
    float: right;
}
  
.purchase-leads {
    padding: 20px 0px;
}

.purchase-leads-forms input {
    border-radius: unset;
}
  
.update-detail button {
    background-color: Update Details;
    background-color: #249FDB;
    width: 91%;
    padding: 12px;
    border-radius: unset;
    border: unset;
    font-size: 16px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-weight: 500;
}


.form-group.change-password button {
    background-color: transparent;
    border: none;
    font-size: 13px;
    color: Password;
    color: #249FDB;
    font-family: 'Poppins';
    font-weight: 500;
    white-space: nowrap;
}

.all-change-password-changes input {
    padding: 27px;
    border-radius: unset;
    margin: 18px 0px;
}

.submit-btn-forms button {
    background-color: #249FDB;
    width: 100%;
    padding: 15px;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
    font-family: 'Poppins';
}

.submit-btn-forms {
    margin-top: 20px;
}

.form-group-ul ul {
    list-style: none;
    padding: 0;
}

/* .form-group-ul ul li::before {
    content: url("../img/check.png");
    position: absolute;
    left: 19px;
} */

.view-info-detail label {
    font-size: 15px;
    color: #000000ab;
    font-family: 'Poppins';
    font-weight: 400;
}
.view-info-detail input {
    border: unset;
    padding: 0 !important;
}
.view-info-detail input::placeholder {
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 500;
}

.view-info-detail textarea#exampleFormControlTextarea1::placeholder {
    font-size: 12px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 500;
}

.view-info-detail textarea#exampleFormControlTextarea1 {
    border: unset;
    resize: none;
    height: 100px;
}

.view-info-detail h3 {
    font-size: 22px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 600;
}

.view-info-detail {
    padding: 0px 40px;
}

section.edit-profile-sec {
    padding: 110px;
}

.all-change-password-changes.some-edit-label-changes input::placeholder {
    color: #00000057;
}

.sign-out button {
    background-color: #249FDB;
    width: 70%;
    margin: 0 auto;
    padding: 10px;
    font-size: 18px;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-weight: 400;
    border: unset;
}

.sign-out {
    padding-left: 22px;
    padding-top: 20px;
}

.all-franchise-info input {
    border: none;
    padding: 0;
}

.all-franchise-info input::placeholder {
    font-size: 16px !important;
    color: #000000 !important;
    font-family: 'Poppins';
    font-weight: 500;
}

.all-franchise-info textarea::placeholder {
    font-size: 15px;
    color: #000000 !important;
    font-family: 'Poppins';
    font-weight: 500;
}
.form-group-ul ul li {
    padding: 10px;
    font-weight: 500;
    /* color: #00000069; */
}

.all-franchise-info textarea {
    border: unset;
    height: 130px !important;
    resize: none;
    padding: 0;
}

.logo-img {
    margin-bottom: 30px;
}

.update-detail {
    margin-top: 40px;
}

.videosss-img img {
    margin: 0px -5px;
    position: relative;
}
.close-t {
    position: relative;
    left: -15px;
    bottom: 30px;
}

section.edit-detail-sec {
    padding: 90px 0px;
}

.state-name h5 {
    font-size: 14px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 400;
    background-color: #249fdb45;
    padding: 22px 43px;
    position: relative;
}

.close-tag {
    position: absolute;
    top: 14px;
    right: 20px;
}

.franchise-broker h2 {
    font-size: 40px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 600;
    padding-bottom: 40px;
}

.state-name {
    padding: 13px 0px;
}

.videosss-img {
    padding: 40px 0px;
}

.all-franchise-infoness textarea {
    resize: none;
    margin-bottom: 40px;
}


.kiHXWb {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 322px;
    max-width: 100% !important;
    height: 48px;
    border: dashed 2px #D4D4D4 !important;
    padding: 8px 16px 8px 8px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    height: 130px !important;
}


.all-select select#add-state {
    padding: 10px 50px;
    margin-left: 20px;
}

.all-select {
    padding: 50px 0px;
}

.all-select select#add-state {
    color: #00000052;
}

.all-tab-things.accounts {
    padding: 20px 50px;
}

.all-table ul.pagination {
    padding-top: 30px;
}

.all-franchise-infoness {
    padding: 0px 8px;
}

.franchise-broker {
    padding: 0px 40px;
}


.form-switch .form-check-input {
    width: 5em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out;
    background-color: #7BC145;
    border: 1px solid #7BC145;
    height: 28px;
}

ul.pagination li a {
    font-size: 14px;
    color: #00151F;
    font-family: 'Poppins';
    font-weight: 400;
}

.fra {
    display: flex;
    justify-content: space-between;
}

.fra-btn a {
    background-color: #249FDB;
    padding: 10px 30px;
    font-size: 12px;
    color: #ffffff;
    font-family: 'Poppins';
    font-weight: 400;
}

td.view-details a {
    color: #249FDB;
}

.accounts .form-group input#exampleInputEmail1 {
    padding: 25px 0px;
}